import '../mui/mui';
import './minipage.sass';

function on_press(e) {
    e.preventDefault();
    for (let input of document.querySelectorAll('input[type="password"]')) {
        input.setAttribute('data-was-password', '1');
        input.setAttribute('type', 'text');
    }
}

function on_release(e) {
    for (let input of document.querySelectorAll('input[data-was-password="1"]')) {
        input.setAttribute('type', 'password');
        input.removeAttribute('data-was-password');
    }
}

document.addEventListener('DOMContentLoaded', () => {
    for (let btn of document.querySelectorAll('.eye-button')) {
        btn.addEventListener('mousedown', on_press);
        btn.addEventListener('touchstart', on_press);
        btn.addEventListener('mouseup', on_release);
        btn.addEventListener('touchend', on_release);
    }
});

document.addEventListener('DOMContentLoaded', () => {
    for (let btn of document.querySelectorAll('.login-eye-button')) {
        btn.addEventListener('mousedown', on_press);
        btn.addEventListener('touchstart', on_press);
        btn.addEventListener('mouseup', on_release);
        btn.addEventListener('touchend', on_release);
    }
});
